body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ### For Printable View ### */

.no-split {
  break-inside: avoid;
  page-break-inside: avoid;
}

.break-before {
  break-before: always;
  page-break-before: always;
}

.break-after {
  break-after: always;
  page-break-after: always;
}

.no-split-between-siblings + div {
  break-before: avoid;
}
